<template>
 
  <div class="grid">
    <div class="col-12">
      <div class="formgrid grid">
        <div class="field col-12 ">
          <Card>
            <template #title> CARRERA: {{ career.carrera }} </template>
            <template #subtitle> {{ career.institucion_educativa }} </template>
            <template #content>
              <strong> Area de Formación:</strong> {{ career.area }} <br>
              <strong> Carga Horaria:</strong> {{ career.carga_horaria }}
            </template>
          </Card>
        </div>
        <!-- <div class="field col-12  ">
          
          <Card>

            <template #content>
              <Panel header="Asignaturas">
                <template #icons>
                  <span class="p-input-icon-right ">
                    <div class="kinput-container">
                      <input type="text" v-model.trim="search"  @keyup.enter="retrieveSubjects()" >
                      <i class="bx bx-search-alt-2" @click="retrieveSubjects()"></i> 
                    </div>
                  </span>
                 </template>
                <div  class="keyrus-scroll">

                  <table class="table  ">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Codigo</th>
                        <th scope="col">Nombre</th>
                      </tr>
                    </thead>
                    <draggable v-model="subjects" tag="tbody" group="subject" @change="log" item-key="id">
                      <template #item="{ element }">
                        <tr class="grabbable">
                      
                          <td>{{ element.code }}</td>
                          <td>{{ element.name }}</td>
                        </tr>
                      </template>
                    </draggable>
                  </table>
                </div>
              </Panel>

            </template>
          </Card>
        </div> -->
      </div>

    </div>
    <div class="col-12">
      <Card>
        <template #title> PLAN DE ESTUDIO </template>

        <template #content>
          <div class="grid">
            <div class="md:col-6 sm:col-12 lg:col-4" v-for="(item, index) in interval_times" :key="index">

              <Panel :header="item.name"   >
                <template #icons>
                    <button class="p-panel-header-icon p-link mr-2 kicon-panel-info " @click="addSubject(index) " >
                                <i class='bx bx-plus text-green-700 '></i>
                    </button>
                    
                </template>

                <table class="table w-full">
                  <thead class="thead-dark">
                    <tr>
                      <th class="col-fixed text-sm" style="width: 75px;" >Codigo</th>
                      <th class="col text-sm">Nombre</th>
                      <th class="col text-sm">Horas</th>
                      <th class="col text-sm"> <span class="">Pre Requisito</span> </th>
                      <th class="col-fiexd text-sm" style="width: 50px;"></th>
                    </tr>
                  </thead>
                  <draggable v-model="item.list" tag="tbody" group="subject" @change="log" item-key="id" class="w-full">
                    <template #item="{ element }">
                      <tr class="grabbable">
                        <td style="width: 75px;" class="text-sm">{{ element.code }}  </td>
                        <td> <span class="text-sm"> {{ element.name }}</span></td>
                        <td style="width: 150px;">
                          <!-- <InputText type="text" class="p-inputtext-sm " v-model="element.hours" /> -->
                          <InputNumber :min="0"  v-model="element.hours"  :class="element.hours<1?`p-inputtext-sm p-invalid`:`p-inputtext-sm`" inputId="integeronly" inputStyle="width: 40px;" /><br>
                          <small class="p-error" id="text-error text-xs " v-if="element.hours<1" >horas > 0 </small>
                        </td>
                        <td v-if="index>0" style="width: 100px;" > 
                          <Dropdown v-model="element.prerequired" showClear :options="subjects_prerequired" optionLabel="code" inputClass="text-sm" placeholder="preriquisto" style="width: 120px;" @change="validItem(element,item.list)"  />
                        </td>
                        <td v-else style="width: 100px;" class="text-sm">
                          Sin Requisito
                        </td>
                        <td><Button icon="pi pi-times" severity="danger" text rounded aria-label="Cancel" @click="removeRow(item.list,element,item)" /></td>
                      </tr>
                    </template>
                  </draggable>
                  <tr>
                    <td colspan="2"> <span class="font-bold">TOTAL</span> </td>
                    <td class="font-bold text-blue-500" > {{ getTotalHours(item.list) }}</td>
                  </tr>
                </table>
                 
              </Panel>

            </div>
          </div>
          <!-- {{ JSON.stringify(subjects_all) }} -->
        </template>
        <template #footer>
          <Button icon="pi pi-check" label="Guardar" @click="saveStudyPlan()" />
          <Button icon="pi pi-times" label="Cancelar" severity="secondary" style="margin-left: 0.5em" @click="$router.go(-1)" />
        </template>
      </Card>
    </div>
    
    <Dialog v-model:visible="dialog" modal :header="`${selected_title}`" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <DataTable :value="subjects"  paginator :rows="5" :rowsPerPageOptions="[5, 10, 15, 20, 50]" v-model:filters="filters" >
          <template #header>
              <div class="flex justify-content-between">
                  <Button type="button" icon="pi pi-file" severity="success" label="Registrar Asignatura" @click="recordSubject()" outlined />
                  <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText v-model="filters['global'].value" placeholder="Buscar" />
                  </span>
              </div>
          </template>
            <Column field="code" header="Codigo"></Column>
            <Column field="name" header="Nombre"></Column>
            <Column field="action" header="">
              <template #body="{ data }">
                
                  <Button type="button" icon="bx bxs-hand-up" label="Seleccionar" @click="selectSubject(data)" outlined />
              </template>
            </Column>
        </DataTable>

          <!-- <div  class="keyrus-scroll">

            <table class="table  ">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Nombre</th>
                </tr>
              </thead>
              <draggable v-model="subjects" tag="tbody" group="subject" @change="log" item-key="id">
                <template #item="{ element }">
                  <tr class="grabbable">
                
                    <td>{{ element.code }}</td>
                    <td>{{ element.name }}</td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div> -->
     
    </Dialog>
    <Dialog v-model:visible="recordDialog" :style="{width: '450px'}" header="Registrar Asignatura" :modal="true" class="p-fluid">
        <div class="field">
          <div class="field">
              <label for="abreviacion">Codigo</label>
              <InputText id="abreviacion" v-model.trim="record.abreviacion" required="true" autofocus :class="{'p-invalid': submitted && !record.abreviacion}" />
              <small class="p-error" v-if="submitted && !record.abreviacion">Es requerido.</small>
          </div>
            <label for="asignatura">Asignatura</label>
            <InputText id="asignatura" v-model.trim="record.asignatura" required="true" autofocus :class="{'p-invalid': submitted && !record.asignatura}" />
            <small class="p-error" v-if="submitted && !record.asignatura">Es requerido.</small>
        </div>
        <!-- <div class="field">
            <label for="comentario">Comentario</label>
            <InputText id="comentario" v-model.trim="record.comentario" autofocus  />
        </div> -->
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" text @click="hideDialog"/>
            <Button label="Guardar" icon="pi pi-check" text @click="saveRecord" />
        </template>
    </Dialog>
  </div>
</template>
<script>
import SubjectDataService from '@/services/SubjectDataService'
import CareerDataService from '@/services/CareerDataServie'
import StudyRegimenDataService from '@/services/StudyRegimenDataService'
import SubjectTypeDataService from '@/services/SubjectTypeDataService';
import draggable from 'vuedraggable'
import _ from 'lodash';
import { FilterMatchMode } from 'primevue/api';

export default {
  data: () => ({
    drag: false,
    drag2: false,
    career: {},
    record: {},
    recordDialog: false,
    interval_times: [],
    subjects: [],
    year_list: [],
    year_list2: [],
    selectedIndex: null,
    selectedInterval: null,
    log: {},
    search: "",
    dialog:false,
    filters:{
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                code: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
    subjects_delete: [],

  }),
  async mounted() {
   await this.retrieveSubjects()
   await this.getCareer()
    
  },
  methods: {
    async retrieveSubjects() {
      //console.log('entrando a la busqueda')
      return await SubjectDataService.searchSubject(this.search.toUpperCase())
        .then( (response) => {
          console.log(response.data)
          this.subjects = response.data
        
        })
        .catch((error)=>{
          console.log("abortando",error)
        })
    },
    async getCareer() {
      return  CareerDataService.get(this.$route.params.career_id)
        .then((response) => {
          this.career = response.data.data
          // let count = this.career.tiempo_estudio
          // console.log(this.career)
          this.setIntervals()
        })
    },
    async setIntervals() {
      // console.log("iniciando conversion")
      let count = this.career.tiempo_estudio
      for (let i = 1; i <= count; i++) {

        let response = await StudyRegimenDataService.getRegimeDegree(`${this.getLiteral(i)} ${this.career.regimen_estudio}`)
        // console.log('regimen grado',response.data) //verifica el regime grado de la db
        if (response.data.statusCode == 200) 
        {
          let list =[]
          // console.log('study plan',this.$store.state.study_plan)
          if(this.$store.state.study_plan)
          {
            // console.log('plan', (this.$route.params.plan_estudio_carrera_id == this.$store.state.study_plan.id)) 
            // console.log('carrera id', this.$route.params.career_id)
            
            if(this.$route.params.plan_estudio_carrera_id == this.$store.state.study_plan.id  && this.$store.state.study_plan.carrera_autorizada_id == this.$route.params.career_id )
            {
              let interval_time =_.find(this.$store.state.study_plan.asignaturas, (asignatura)=>{ return asignatura.name === `${response.data.data.regimenGrado}`} ) 
              // console.log('interval time',interval_time) //busca dentro del objeto guardado temporalmente
              
              if(interval_time)
              {
                interval_time.semestres.forEach( asignatura => {
                  let asignatura_selected = _.find(this.subjects,(subject)=>{ return subject.code === asignatura.abreviacion && subject.name === asignatura.asignatura })
                  asignatura_selected.hours = asignatura.horas
                  if(asignatura.prerequisito)
                  {
                    let prerequisito = _.find(this.subjects,(subject)=>{ return subject.code === asignatura.prerequisito.anteriorPlanEstudioAsignatura.asignaturaTipo.abreviacion })
                    console.log('prerequisito',prerequisito)
                    if(prerequisito)
                    {
                      // let asignatura_dependiente = _.find(list,(item)=>{return item.code === prerequisito.code })
                      // console.log('asignatura pedepndiente',asignatura_dependiente)
                      // console.log('lista',list)
                      // if(asignatura_dependiente)
                      // {
                        asignatura_selected.prerequired = {id:asignatura.prerequisito.anteriorPlanEstudioAsignatura.asignaturaTipo.id,name:asignatura.prerequisito.anteriorPlanEstudioAsignatura.asignaturaTipo.asignatura,code:asignatura.prerequisito.anteriorPlanEstudioAsignatura.asignaturaTipo.abreviacion}
                      // }
                    }
                  }
  
                  list.push(asignatura_selected)
                }); 
              }
            }
          }
          
          this.interval_times.push({ 
                                      plan_estudio_carrera_id: this.$route.params.plan_estudio_carrera_id,
                                      regimen_grado_tipo_id: response.data.data.id,
                                      name: `${response.data.data.regimenGrado}`,

                                      list: list })
          
          
        } else {
          console.log(`no se encotro: ${this.getLiteral(i)} ${this.career.regimen_estudio}`)
        }


      }
    },
    async saveStudyPlan()
    {
      let array_payload = []
      let valid = true

      this.interval_times.forEach((item,index) => {
        
        console.log('interval time',item)
        item.list.forEach( (asignatura,index_asginatura) => {
          let prerequired = asignatura.prerequired?asignatura.prerequired.id:0
          if(index === 0)
          {
            prerequired = 0
          }
          if(asignatura.hours<1)
          {
            this.$toast.add({ severity: 'error', summary: `Error la ${asignatura.name}`, detail: 'Las hora asignada debe ser mayor a 0', life: 3000 });
            valid=false
          }else{
            
            array_payload.push({
              plan_estudio_carrera_id: parseInt(item.plan_estudio_carrera_id),
              regimen_grado_tipo_id: parseInt(item.regimen_grado_tipo_id),
              asignatura_tipo_id: parseInt(asignatura.id),
              horas: parseInt(asignatura.hours),
              prerequisito_id: prerequired,
              index: index_asginatura
            })
          }
          });

      });
      if(valid)
      {

        let payload = { subjects_delete: this.subjects_delete, subjects: array_payload } 
        console.log('payload', payload)
        let response = await SubjectDataService.saveStudyPlan(payload)
        console.log(response.data)
        this.$router.push(`/career/${this.$route.params.career_id}`)
        //  use this when you need show message in another route
       
        if(response.data.statusCode== 201)  
        {
           this.$store.dispatch('showMessage',{severity:'success',title:'Plan de estudio',message:'Se guardo correctamente el Plan de Estudio'})
        }

      }
     
    },
    selectSubject(subject)
    {
      this.interval_times[this.selectedIndex].list.push(subject)
      console.log(subject)
      // console.log(list)
    },  

    validItem(item,list)
    {
      // console.log(item)
      // console.log(list)
      if(item?.prerequired && list)
      { 
        let resutl = _.find(list,(objeto)=>{ return objeto.code === item.prerequired.code } )
        console.log('result', resutl)
        if(resutl)
        {
          item.prerequired = null
          this.$toast.add({ severity: 'error', summary: 'Error Prerequisito', detail: 'Un asignatura del mismo semestre no puede ser prerequisito', life: 5000 });
        }
        // console.log('si tiene prerequisito')
        // console.log('pero lo borre')
        // item.prerequired = null
      }
      // if(item){
      //   let resutl = _.find(list,(objeto)=>{ return objeto.code === item.code } )
      //   if(resutl)
      //   {
      //     this.$toast.add({ severity: 'danger', summary: 'Prerequisito ', detail: 'Un asignatura del mismo semestre no puede ser prerequisito', life: 5000 })
          
      //   }
      // }
        
    },

    removeRow(list,subject,interval) 
    {
      // let 
      
      let prerequired = _.find(this.subjects_all,(subject)=>{
        return subject.prerequired?.code === subject.code
      })
      if(prerequired)
      {
        this.$toast.add({ severity: 'error', summary: 'Error Prerequisito', detail: 'No se puede eliminar esa asignatura debido a que esta como prerequisito de otras asignatura ', life: 5000 });
        console.log('no se puede eliminar',prerequired)
      }else{
        // console.log('elimnable')
        console.log(subject)
        console.log(interval)
        let payload = {
              plan_estudio_carrera_id: parseInt(interval.plan_estudio_carrera_id),
              regimen_grado_tipo_id: interval.regimen_grado_tipo_id,
              asignatura_tipo_id: subject.id,
              horas: subject.hours,
              prerequisito_id: prerequired,
              index: 0
            }
        this.subjects_delete.push( payload )
        let index =  list.indexOf(subject)
        list.splice(index,1)
      }
      //para eliminar
      
    },
    recordSubject()
    {
      this.dialog = false
      this.record = {}
      this.recordDialog = true
    },

    addSubject(index,item){
      console.log(index)
      this.selectedIndex = index
      this.selectedInterval = item
      this.dialog = true
    },

    saveRecord(){
      this.record.abreviacion = this.record.abreviacion.toUpperCase()
      this.record.asignatura = this.record.asignatura.toUpperCase()
      SubjectTypeDataService.create(this.record).then(async (data) => {
                    console.log('creando nuevo registro')
                    if(!data.data.statusCode === 201){
                        this.record = {}    
                        this.recordDialog = false;
                        this.$toast.add({severity:'danger', summary: 'Error', detail: 'Registro no creado', life: 3000});
                    }else{
                      this.interval_times[this.selectedIndex].list.push(data.data.data)
                      this.$toast.add({severity:'success', summary: 'Correcto', detail: 'Registro creado', life: 3000}); 
                      this.record = {}      
                      this.recordDialog = false;
                      await this.retrieveSubjects()
                    }
                    
                });   
    },

    getTotalHours(list)
    {
      let hours = 0
      list.forEach(element => {
        console.log(element)
        hours += parseInt(element.hours)
      });
      // console.log(list)
      return hours

    },

    getLiteral(number) {
      let literal = ''
      switch (number) {
        case 1:
          literal = "PRIMER"
          break;
        case 2:
          literal = "SEGUNDO"
          break;
        case 3:
          literal = "TERCER"
          break;
        case 4:
          literal = "CUARTO"
          break;
        case 5:
          literal = "QUINTO"
          break;
        case 6:
          literal = "SEXTO"
          break;
        case 7:
          literal = "SEPTIMO"
          break;
        case 8:
          literal = "OCTAVO"
          break;
        case 9:
          literal = "NOVENO"
          break;
        case 10:
          literal = "DECIMO"
          break;


      }
      return literal
    }
    
  },
  computed:{
    subjects_prerequired()
    {
      let list = []

      this.interval_times.forEach(element => {
        element.list.forEach(item  => {
          list.push({id:item.id,name:item.name,code:item.code})
          // list.push(item)
        });
      });

      return list
    },
    subjects_all()
    {
      let list = []

      this.interval_times.forEach(element => {
        element.list.forEach(item  => {
          list.push(item)
          // list.push(item)
        });
      });

      return list
    },
    selected_title(){
      let title = ''
      if(this.selectedIndex>=0 )
      {
        let item = this.interval_times[this.selectedIndex]
        if(item)
        {
          title = `ASIGNATURAS ${item.name} : ${item?.list?.length}`
        }
      }
      return title
    }
  },
  components: {
    draggable,
  },

}
</script>