import http from "@/http-common";
class FormacionSuperiorArtisticaService {
    create(data) {
      console.log('entra al puente',data);
      return http.post("/formacion-superior-artistica", data);
    }

    editar(data,id) {
      console.log('entra al puente',data);
      console.log('ID', id)
      return http.put(`/formacion-superior-artistica/${id}`, data);
    }

    eliminar(id) {
      return http.post(`/formacion-superior-artistica/${id}`);
    }

    getAll(){
      return http.get("/formacion-superior-artistica");
    }

    getById(id){
      return http.get(`/formacion-superior-artistica/${id}`);
    }
    
    getCursosByInstitutoId(id){
      return http.get(`/formacion-superior-artistica/cursos_institutos/${id}`);
    }
    
    storeParalelos(data){
      console.log('punete ',data);
      return http.post(`/formacion-artistica-paralelo`,data);
    }

    getParalelos(id){
      return http.get(`/formacion-artistica-paralelo/${id}`);
    }

    getParaleloData(id){
      return http.get(`/formacion-artistica-paralelo/paralelo_data/${id}`);
    }

    getParaleloById(id){
      return http.get(`/formacion-artistica-paralelo/paralelo/${id}`);
    }

    crearEstudianteExiste(data) {
      console.log('entra al puenteeeee',data);
      return http.post("/formacion-superior-artistica", data);
    }


    inscripcionEstudianteArtistica(data) {
      console.log('entra al puente',data);
      return http.post("/estudiante-inscripcion-artistica", data);
    }

    listarInscripcionEstudianteArtistica(id) {
      return http.get(`/estudiante-inscripcion-artistica/${id}`);
    }

    delete(id)
    {
        return http.delete(`/formacion-superior-artistica/${id}`)
    }

    getTeacherParalell(id)
    {
      console.log("entra puente", id)
      return http.get(`/formacion-artistica-paralelo/teacher_paralelo/${id}`)
    }
    
  }
  
  export default new FormacionSuperiorArtisticaService();