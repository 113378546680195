import http from "@/http-common";
class InscriptionTeacherService {
  getAllBySieYear(sie){
    return http.get(`/maestro-inscripcion/getAllDocentesByUeGestion/${sie}`);
  }

  getTeacherInstitution(institucion_educactiva_id)
  {
    return http.get(`/maestro-inscripcion/institution-teachers/${institucion_educactiva_id}`);
  }
  
  getAllById(id){
    return http.get(`/maestro-inscripcion/getMaestroInscripcionById/${id}`);
  }

  // getAll(){
  //   return http.get("/maestro-inscripcion");
  // }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  create(data) {
    return http.post("/maestro-inscripcion", data).catch((error)=>{
      return error.response;
    });
  }

  update(id, data) {
    return http.put(`/maestro-inscripcion/`, data);
  }

  updateStatus(data) {
    return http.put(`/maestro-inscripcion/changeStatusById`, data).catch((error)=>{
      return error.response;
    });
  }

  getDetail(carnet_identidad,codigo_ritt, gestion_id)
  {
    return http.get(`/maestro-inscripcion/teacher-detail/${carnet_identidad}/${codigo_ritt}/${gestion_id}`)
  }

  getTeacherById(id)
  {
    return http.get(`/maestro-inscripcion/${id}`)

  }

  // delete(id) {
  //   return http.delete(`/maestro-inscripcion/${id}`);
  // }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new InscriptionTeacherService();