<template>
    <Toast position="bottom-right" />
        <Card>
        <template #title>
            <div class="flex flex-column w-full">
                <span> CURSO: {{ course.nombre }}</span>
                <span class="text-sm font-s text-blue-300">{{ course.institucion_educativa_id}} : {{ course.instituto?.institucionEducativa}} </span> 
            </div>
        </template>
        <template #content>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <Panel header="Datos Generales del Curso" toggleable >
                       
                        <div class="grid">
                            <div class="col-6">
                                <table class="table table-sm">
                                    <tbody>
                                       
                                        <tr>
                                            <td class=" text-end "><strong>Nombre:</strong> </td>
                                            <td class="">{{ course.nombre }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Área:</strong> </td>
                                            <td class=""> {{ course.disciplinaTipo?.area?.area}}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Disciplina:</strong> </td>
                                            <td class=""> {{ course.disciplinaTipo?.disciplina}}</td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="col-6">
                                <table class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td class=" text-end "> <strong>Nivel de Formación:</strong></td>
                                            <td class=""> {{ course.nivelAcademico?.nivelAcademico }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                
                    </Panel>
                </div>

                <div class="field col-12 md:col-6" v-if="course">
                    <CourseManagementArtistic @paraleloGuardado="createParallel"></CourseManagementArtistic>

                </div>
                <!--<div class="field col-12">-->
                <!--    <div class="flex justify-content-between"> -->
                 <!--       <SplitButton class="w-16rem" :label="state_selected?.estado??''" icon="pi pi-map" :model="items" v-if="career" />-->
                        <!--div class="flex justify-content-end">
                            <Button icon="pi pi-plus" label="Adicionar Resolución de Plan de Estudio" severity="secondary" size="small" @click="addResolution()" v-if="has_period && career" />
                            <Message v-else>Debe Adicionar un Periodo Academico</Message>
                        </div-->
                <!--    </div>-->
                <!--</div>-->
                
                        <div class="field col-12 md:col-6" v-for="(paralelo,index) in paralelos" :key="index">
                            <!--<Panel :header="`Resolución de Plan de Estudio `" 
                            toggleable 
                                :collapsed="true" 
                                @update:collapsed="(isCollapsed) => !isCollapsed && getTeacherByParalell(paralelo)"
                                :pt="{
                                    header: { class: 'bg-secondary' },
                                    title: { class: 'text-white' },
                                    icons: { class: 'text-white'},
                                    toggler: { class: 'text-white hover:bg-primary-reverse' }
                                }"    
                            >-->
                            <Panel 
                            :header="`Paralelos `" 
                            toggleable 
                            :collapsed="true"
                            @toggle="handlePanelToggle(paralelo)"
                            :pt="{
                                header: { class: 'bg-secondary' },
                                title: { class: 'text-white' },
                                icons: { class: 'text-white'},
                                toggler: { class: 'text-white hover:bg-primary-reverse' }
                            }"
                            >

                                <template #header>
                                    <div class="flex align-items-center ">
                                        <span class="text-white font-bold pr-3"> PARALELO : {{ paralelo.nombre_paralelo ?? ''}}</span>
                                        <span class="font-semibold" style="color: #53afd3;" > TURNO :{{ paralelo.turno?.turno }}</span>
                                    </div>
                                </template>
                                <template #icons>
                                    <button class="p-panel-header-icon p-link mr-2" @click="checkStudentsArtistic(paralelo)">
                                        <span class="bx bxs-plus-circle text-green-400 "></span>
                                    </button>
                    
                                    <!--<div class="p-panel-header-icon " style="border-radius: 0px; width: 35px;" >
                                        <i class="bx bxs-user-detail text-white " ></i>
                                        <Badge :value="paralelo?.total_estudiantes" severity="success" style="font-size: 8px; margin-bottom: 1px !important;" ></Badge>
                                    </div>-->
                                </template>
                                
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>Docente Asignado</th>
                                            <th>Cantidad de Estudiantes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="teachersByParalelo[paralelo.id]">
                                            <td>{{ teachersByParalelo[paralelo.id].nombre }} {{ teachersByParalelo[paralelo.id].paterno }} {{ teachersByParalelo[paralelo.id].materno }}</td>
                                            <td>{{ studentsByParalelo[paralelo.id] ? studentsByParalelo[paralelo.id].length : 0 }}</td>                                        </tr>
                                        <tr v-else>
                                            <td colspan="2">No hay docentes asignados</td>
                                        </tr>
                                    </tbody>
                                </table>
                                

                                <!--<table class="table w-full" v-if="estudiantesPorParalelo[paralelo.id]?.length">
                                    <thead>
                                    <th>Nro</th>
                                    <th>CI</th>
                                    <th>Paterno</th>
                                    <th>Materno</th>
                                    <th>Nombres</th>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(estudiante, idx) in estudiantesPorParalelo[paralelo.id]" :key="idx">
                                        <td>{{ idx + 1 }}</td>
                                        <td>{{ estudiante.carnetIdentidad }}</td>
                                        <td>{{ estudiante.paterno }}</td>
                                        <td>{{ estudiante.materno }}</td>
                                        <td>{{ estudiante.nombre }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p v-else class="text-center text-gray-500">No hay estudiantes registrados en este paralelo.</p>-->


                                <!--<table class="table w-full">
                                    <thead>
                                        <th>Nro</th>
                                        <th>CI</th>
                                        <th>Paterno</th>
                                        <th>Materno</th>
                                        <th>Nombres</th>
                                        
                                    </thead>
                                   
                                    <tbody>
                                        <tr v-for="(estudiante,index) in estudiantes" :key="index">
                                            <td>{{ index+1 }}</td>
                                            <td>{{ estudiante.persona.carnetIdentidad }}</td>
                                            <td>{{ estudiante.persona.paterno }}</td>
                                            <td>{{ estudiante.persona.materno }}</td>
                                            <td>{{ estudiante.persona.nombre }}</td>
                                        </tr>

                                    </tbody>
                                </table>-->
                            
                            </Panel>
                        </div>
                    
               
            </div>
            
        </template>
    </Card>

    <!--<Dialog v-model:visible="dialog" modal :header="study_resolution.id?'Editar Resolución de Plan de Estudio':'Registro de Resolución de Plan de Estudio'" :style="{ width: '50vw' }">
        <Message v-if="!study_resolution.id">Las Resoluciones de Plan de Estudio <strong>0210/2023 , 0637/2019</strong>  ya se encuentran disponibles en el listado de resoluciones de planes de Estudio vigentes </Message>
        <div class="formgrid grid">
            
            
            <div class="col" v-if="!study_resolution.id" >
                    <label >Desea usar una Resolucion de Plan de Estudio General <strong>0210/2023 o 0637/2019</strong>?</label>
                    <SelectButton v-model="current_resolution_question" :options="options" aria-labelledby="basic" />
            </div>
            
            <Divider layout="vertical" v-if="!study_resolution.id" />
            <div class="col " v-if="current_resolution_question=='SI'" >
                Resoluciones de planes de estudio vigentes:
                <br>
                <br>   
                <div v-for="(item, index ) in current_resolutions" :key="index" class="flex align-items-center">
                    
                    <RadioButton v-model="current_resolution"  :value="item"  />
                    <label class="ml-3 mt-2"> <strong>{{ item.numero_resolucion }} <Tag severity="info" :value="item.denominacion" v-if="item.denominacion"></Tag> <Tag severity="success" :value="item.mencion" v-if="item.mencion"></Tag> </strong> </label>
                </div>-->
                
                <!-- <Listbox v-model="current_resolution" :options="current_resolutions" optionLabel="numero_resolucion" class="w-full md:w-14rem" /> -->
           <!-- </div>
            <div :class="study_resolution.id ? 'field col-12 md:col-12' : 'field col-12 md:col-8'" v-if="current_resolution_question=='NO' || study_resolution.id" >
                <div class="formgrid grid">
                    <div class="field col-12 md:col-12">
                        <label >Numero de Resolución</label>
                        <InputMask id="basic" v-model="study_resolution.numero_resolucion" :disabled="study_resolution.id"  class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                     
            -->
                        <!-- <InputText type="text"   /> -->
            <!--        </div>
                    <div class="field col-12 md:col-12">
                        <label >Fecha de Resolución</label>
                        <Calendar class="p-inputtext-sm w-full" v-model="study_resolution.fecha_resolucion"  dateFormat="dd/mm/yy" :disabled="study_resolution.id"/>
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Denominación del Titulo</label>
                        <InputText type="text" class="p-inputtext-sm w-full" v-model="study_resolution.denominacion" />
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Mención</label>
                        <InputText type="text" class="p-inputtext-sm w-full" v-model="study_resolution.descripcion" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="createStydeResolution()" autofocus v-if="!study_resolution.id" :disabled="button_desabled" />
            <Button label="Actualizar" icon="pi pi-check" @click="updateStudyResolution()" autofocus v-else :disabled="button_desabled" />
        </template>
    </Dialog>-->

    <!--<Dialog v-model:visible="dialog_history" modal :header="`Historial del Plan de Estudio ${study_plan?.study_plan?.numeroResolucion}`" :style="{ width: '50vw' }">
        <Timeline :value="study_plan?.histories">
            <template #opposite="slotProps">
                <div class="flex flex-column">
                    <span> {{slotProps.item.estadoInstituto.estado}} </span>
                    <small class="p-text-secondary">{{slotProps.item.fechaRegistro}}</small>
                </div>
            </template>
            <template #content="slotProps">
                <div class="flex flex-column">
                    
                    <InlineMessage :severity="slotProps.item.estadoInstituto.id === 8 || slotProps.item.estadoInstituto.id == 4?'error':'info' ">{{slotProps.item.observacion  }}</InlineMessage>
                </div>
               
               
            </template>
        </Timeline>
    </Dialog>-->

    <!--<Dialog v-model:visible="dialog_edit_resolution" modal :header="`Editar Resolución de Plan de Estudio `" :style="{ width: '40vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-12">
                <label >Numero de Resolución</label>
                <InputMask id="basic" v-model="resolution_plan_edit.numeroResolucion"   class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                
-->
                <!-- <InputText type="text"   /> -->
        <!--   </div>
            <div class="field col-12 md:col-12">
                <label >Fecha de Resolución</label>
                <Calendar class="p-inputtext-sm w-full" v-model="resolution_plan_edit.fecha_resolucion_date"  dateFormat="dd/mm/yy" />
            </div>
            <div class="field col-12 md:col-12">
                <label >Denominación del Titulo</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="resolution_plan_edit.denominacion" />
            </div>
            <div class="field col-12 md:col-12">
                <label >Mención</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="resolution_plan_edit.descripcion" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog_edit_resolution = false" text />
            <Button label="Actualizar" icon="pi pi-check" @click="updateResolutionPlan()" />
        </template>
    </Dialog>-->
    
 
</template>
<script>

import FormacionSuperiorArtisticaService from '@/services/FormacionSuperiorArtisticaService'
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
import CourseManagementArtistic from '@/components/CourseManagementArtistic.vue';
import InstitutionDataService from '@/services/InstitutionDataService'
import InscriptionTeacherService from '@/services/InscriptionTeacherService';
import { nextTick } from 'vue';

export default
{
    
    data:()=>({
        course:{},
        paralelos:[],
        studentsByParalelo: {},
        dialog:false,
        current_resolution_question:'',
        current_resolution:null,
        current_resolutions:[],
        study_resolution:{},
        study_resolutions:[],
        states: [],
        options:['SI','NO'],
        current_period: null,
        state_selected: null,
        gestion: null,
        periodo: null,
        has_period: false,
        button_desabled: false,
        dialog_history: false,
        study_plan: null,
        dialog_edit_resolution: false,
        resolution_plan_edit: null,
        teachersByParalelo: {},
        lastClickedPanel: null,
    }),
    async mounted(){
        this.getCursoFormacionArtistica();
        this.getParalelos();
        this.getTurnos();
        this.getInstitution()
        //this.getCareer()
        //Promise.all([this.getOperativeActive(),this.getEstados(),this.getCareer(),this.reloadPage()])

    },
    methods:
    {
        getCursoFormacionArtistica(){
            console.log('obteniendo id de curso',this.$route.params.id);
            FormacionSuperiorArtisticaService.getById(this.$route.params.id)
            .then((response)=>{
                //this.institution = response.data
                console.log('response.data===>',response.data);
                this.course = response.data.data;
            })
        },
        checkStudentsArtistic(paralelo)
        {
            console.log('paralelo',paralelo);
            console.log('entra a la planilla',this.$route.params.id);
            this.$router.push(`/classroomArtistica/${this.$route.params.id}/${paralelo.id}`)
        },

        /*listStudents(paralelo) {            
            FormacionSuperiorArtisticaService.listarInscripcionEstudianteArtistica(paralelo.id)
                .then((response) => {
                    if (Array.isArray(response.data.data)) {
                        // Extraer solo los datos de 'persona' de cada inscripción
                        const estudiantes = response.data.data.map(inscripcion => inscripcion.persona);
                        console.log("Lista de estudiantes extraída:", estudiantes);
                        this.estudiantesPorParalelo[paralelo.id] = estudiantes;
                        console.log("estu x paralelo", this.estudiantesPorParalelo[paralelo.id])
                    } else {
                        console.error("response.data.data no es un array.");
                    }
                })
                .catch((error) => {
                    console.error("Error al cargar estudiantes:", error);
                    this.$set(this.estudiantesPorParalelo, paralelo.id, []); // En caso de error, evitar que quede vacío
                });
        },*/

        async getStudentsByParallel(paralelo) {
            try {
                const response = await FormacionSuperiorArtisticaService.listarInscripcionEstudianteArtistica(paralelo.id);
                this.studentsByParalelo[paralelo.id] = response.data.data; 
                //console.log('estudiantes==>>', this.studentsByParalelo);

            } catch (error) {
                console.error('Error obteniendo estudiantes:', error);
            }
        },

        async handlePanelToggle(paralelo) {
            try {
                await nextTick(); // Esperar a que el DOM se haya actualizado
                console.log("Cargando docentes y estudiantes para el paralelo:", paralelo);

                // Ejecutar ambas funciones en paralelo
                await Promise.all([
                    this.getTeacherByParalell(paralelo),
                    this.getStudentsByParallel(paralelo)
                ]);
                
                console.log("Datos cargados correctamente para el paralelo:", paralelo);
            } catch (error) {
                console.error("Error al cargar docentes y estudiantes:", error);
            }
        },
       
        /*async handlePanelToggle(paralelo) {
            await nextTick(); // Esperar a que el DOM se haya actualizado

            // Llamar a la función de obtener el docente solo después de la actualización visual
            console.log("Cargando docentes para el paralelo:", paralelo);
            await this.getTeacherByParalell(paralelo); 
            await this.getStudentsByParallel(paralelo)

        },*/

        async getTeacherByParalell(paralelo) {
            try {
            const teacherResponse = await FormacionSuperiorArtisticaService.getTeacherParalell(paralelo.id);
            if (!teacherResponse.data) throw new Error("No hay docentes para este paralelo.");
            const teacherDetailResponse = await InscriptionTeacherService.getTeacherById(teacherResponse.data.docente_id);
            this.teachersByParalelo[paralelo.id] = teacherDetailResponse.data.persona;
            console.log("teachers" , this.teachersByParalelo)

            } catch (error) {
            console.error("Error obteniendo docente:", error);
            }
        },

        getParalelos(){
            FormacionSuperiorArtisticaService.getParalelos(this.$route.params.id)
            .then((response)=>{
                this.paralelos = response.data.data;
                console.log('this.paralelos',this.paralelos);
            })
        },
        async getTurnos()
        {
            let response = await AcademicOffertDataService.getTurnos()
            //console.log('respturnos',data)
            this.turnos = response.data;
            console.log('this.turnos',this.turnos);
        },

        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                console.log("institution", response.data)
            })
        },
        createParallel(data){
            
            console.log("actualizando listado",data)
            this.paralelos.push(data)
            console.log("nuevo paralelos", this.paralelos)
        }
    },
    components:{
        CourseManagementArtistic
    }
}
</script>
