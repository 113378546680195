import http from "@/http-common";

class AuthService {
    login(user) {
      return http
        .post('/auth/login', {
          username: user.username,
          password: user.password
        })
        .then(response => {
          if (response.data.statusCode===200) {
            localStorage.setItem('user', JSON.stringify(response.data));
            let rol = {
                "rol_tipo_id": "5",
                "rol": "DIRECTOR"
            }
            let institution = {}
            
            response.data.institutos.forEach(user_institution => {
              institution = user_institution
            });

            if(institution)
            {
              institution.roles.forEach(user_rol => {
                rol = user_rol
              });
            }
            
            localStorage.setItem('rol', JSON.stringify(rol));
            localStorage.setItem('institution', JSON.stringify(institution));
            // console.log('login response',response)
            http.defaults.headers.common['Authorization'] =  `Bearer ${response.data.token}`;
          }
          
          return response.data;
        });
    }
  
    logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('rol');
      localStorage.removeItem('institution');
    }
    
    check(id)
    {
      return http.get( `/user/checkToken`);
    }
    
    changeRol(rol)
    {
      // console.log(rol)
      localStorage.setItem('rol', JSON.stringify(rol));
    }
    
    changeInstitution(institution)
    {
      localStorage.setItem('institution', JSON.stringify(institution))
    }
    // register(user) {
    //   return axios.post(API_URL + 'signup', {
    //     username: user.username,
    //     email: user.email,
    //     password: user.password
    //   });
    // }
    //getUsers()
    //{
    //  return http.get('/user/list')
    //}
    
    getUsers(page,limit)
    {
      return http.get(`/user/listm?page=${page}&limit=${limit}`)
    }

    getUser(ci)
    {
      return http.get(`/user/getByCi/${ci}`)
    }

    changePassword(user)
    {
      return http.put('/user/changePassword',user)
    }

  }
   export default new AuthService();