<template>
    <div class="card card-w-title">
        <div class="formrid grid">
            <div class="field col-12 md:col-4 mb-0">
                <label for="education">Nivel de Formación</label>
                <AutoComplete v-model="form.education" id="education" optionLabel="formacion" :suggestions="filteredEducation" @complete="searchEducation" dropdown forceSelection />
                    <small v-show="validationErrors.education && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0">
                <label for="specialty">Especialidad</label>
                <AutoComplete v-model="form.specialty" id="specialty" optionLabel="especialidad" :suggestions="filteredSpecialty" @complete="searchSpecialty" dropdown forceSelection />
                    <small v-show="validationErrors.specialty && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0">
                <label for="job">Cargo</label>
                <AutoComplete v-model="form.job" id="job" optionLabel="cargo" :suggestions="filteredJob" @complete="searchJob" dropdown forceSelection />
                    <small v-show="validationErrors.job && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0">
                <label for="languageStudied">Idioma que habla actualmente</label>
                <AutoComplete v-model="form.languageStudied" id="languageStudied" optionLabel="idioma" :suggestions="filteredLanguageStudied" @complete="searchLanguageStudied" dropdown forceSelection />
                    <small v-show="validationErrors.languageStudied && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0">
                <label for="assignmentStartDate">Fecha de Ingreso</label>
                <Calendar v-model="form.assignmentStartDate" id="assignmentStartDate" dateFormat="dd/mm/yy" />
                <small v-show="validationErrors.assignmentStartDate && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0">
                <label for="funding">Fuente de Financiamiento</label>
                <AutoComplete v-model="form.funding" id="funding" optionLabel="financiamiento" :suggestions="filteredFunding" @complete="searchFunding" dropdown forceSelection />
                    <small v-show="validationErrors.funding && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0">
                <label for="normalist">¿Cuenta con ITEM?</label>
                <SelectButton v-model="form.normalist" id="normalist"  :options="normalist" aria-labelledby="basic" />
                <small v-show="validationErrors.normalist && submitted" class="p-error">es requerido</small>
            </div>
            <div class="field col-12 md:col-4 mb-0" v-if="form.normalist == 'SI'" >
                <label for="item">Nro de Item</label>
                <InputText id="item" type="text" v-model="form.number_item" />
            </div>  
            <div class="field col-12 md:col-4 mb-0" v-if="form.normalist == 'SI'">
                <label for="item">Horas Item</label>
                <InputText id="item" type="text" v-model="form.horas_item" />
            </div>  
            <div class="field col-12 md:col-4 mb-0"  v-if="form.normalist == 'SI'">
                <label for="item">RDA/RP</label>
                <InputText id="item" type="text" v-model="form.rda" />
            </div>  
            <!--div class="field col-12 md:col-8 mb-0">
                <label for="educationDescription">Descripción</label>
                <InputText id="educationDescription" type="text" v-model="form.educationDescription" />
                <small v-show="validationErrors.educationDescription && submitted" class="p-error">es requerido</small>
            </div>  
            <div class="field col-12 md:col-4 mb-0">
                <label for="braile">¿Usa braile?</label>
                <SelectButton v-model="form.braile" id="braile" :options="braile" aria-labelledby="basic" />
                <small v-show="validationErrors.braile && submitted" class="p-error">es requerido</small>
            </div>
            
            <div class="field col-12 md:col-4 mb-0">
                <label for="assignmentEndDate">Asignación fecha fin</label>
                <Calendar v-model="form.assignmentEndDate" id="assignmentEndDate" dateFormat="dd/mm/yy" />
                <small v-show="validationErrors.assignmentEndDate && submitted" class="p-error">es requerido</small>
            </div-->
        </div>
        <div class="pt-3 flex justify-content-between align-items-center">
            <div class="text-left">
                <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
            </div>
            <div class="text-left">
                <Button label="Registrarrrrr" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>
            </div>
        </div>
    </div>
</template>
        

<script setup>
import { ref, onMounted, onBeforeMount, defineEmits, defineProps, onActivated, onUnmounted } from 'vue';
import EducationTypeDataService from '@/services/EducationTypeDataService';
import FundingTypeDataService from '@/services/FundingTypeDataService';
import JobTypeDataService from '@/services/JobTypeDataService';
import SpecialtyTypeDataService from '@/services/SpecialtyTypeDataService';
import LanguageTypeDataService from '@/services/LanguageTypeDataService'


const validationErrors = ref({});

const props = defineProps({
    formData: {
        type: Object,
        default: null
    },
    type: {
        type: String,
        default: ''
    }
});

const form = ref({
    education: null,
    funding: null,
    job: null,
    specialty: null,
    educationDescription: '',
    languageStudied: null,
    normalist: false,
    braile: false,
    assignmentStartDate: '',
    assignmentEndDate: '',
    item: ''
});

const education = ref({});
const funding = ref({});
const job = ref({});
const specialty = ref({});
const languageStudied = ref({});
const normalist = ref(['NO', 'SI']);
const braile = ref(['NO', 'SI']);

onMounted(async() => {
    await Promise.all([listEducation(),listFunding(),listJob(),listSpecialty(),listLanguageStudied()]);
    if( props.formData.id === 0 ){
        return;
    }
    form.value.education = props.formData.education
    form.value.educationDescription = props.formData.educationDescription
    form.value.item = props.formData.item
    form.value.funding = props.formData.funding
    form.value.job = props.formData.job
    form.value.specialty = props.formData.specialty
    form.value.languageStudied = props.formData.languageStudied
    form.value.normalist = props.formData.normalist ? 'SI' : 'NO'
    form.value.braile = props.formData.braile ? 'SI' : 'NO'
    form.value.assignmentStartDate = formatFecha(props.formData.assignmentStartDate, 1)
    // form.value.assignmentEndDate = formatFecha(props.formData.assignmentEndDate, 1)
    form.value.number_item = props.formData.item
    form.value.horas_item = props.formData.horas_item
    form.value.rda = props.formData.rda
    
});

// onBeforeMount(() => {

// });


onActivated(() => {
    
});

onUnmounted(() => {
    // console.log('unmounted has been called')
});

const listEducation = () => {
    EducationTypeDataService.getAll().then((data) => {
        education.value = data.data.data;
        if (!Object.is(props.formData.education)){
            form.value.education = (education.value)[findIndexDataById(education.value, props.formData.education)];
        } 
    });
}
const filteredEducation = ref();
const searchEducation = (event) => {
    // let _items = [...education.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredEducation.value = [...education.value];
        } else {
            filteredEducation.value = education.value.filter((item) => {
                return item.formacion.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}


const listFunding = () => {
    FundingTypeDataService.getAll().then((data) => {
        funding.value = data.data.data;
        if (!Object.is(props.formData.funding)){
            form.value.funding = (funding.value)[findIndexDataById(funding.value, props.formData.funding)];
        } 
    });
}
const filteredFunding = ref();
const searchFunding = (event) => {
    // let _items = [...funding.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredFunding.value = [...funding.value];
        } else {
            filteredFunding.value = funding.value.filter((item) => {
                return item.financiamiento.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const listJob = () => {
    JobTypeDataService.getAll().then((data) => {
        job.value = data.data.data;
        if (!Object.is(props.formData.job)){
            form.value.job = (job.value)[findIndexDataById(job.value, props.formData.job)];
        } 
    });
}
const filteredJob = ref();
const searchJob = (event) => {
     let _items = [...job.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredJob.value = [...job.value];
        } else {
            filteredJob.value = job.value.filter((item) => {
                return item.cargo.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const listSpecialty = () => {
    SpecialtyTypeDataService.getAll().then((data) => {
        specialty.value = data.data.data;
        if (!Object.is(props.formData.specialty)){
            form.value.specialty = (specialty.value)[findIndexDataById(specialty.value, props.formData.specialty)];
        } 
    });
}
const filteredSpecialty = ref();
const searchSpecialty = (event) => {
    // let _items = [...specialty.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredSpecialty.value = [...specialty.value];
        } else {
            filteredSpecialty.value = specialty.value.filter((item) => {
                return item.especialidad.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const listLanguageStudied = () => {
    LanguageTypeDataService.getAll().then((data) => {
        languageStudied.value = data.data.data;
        if (!Object.is(props.formData.languageStudied)){
            form.value.languageStudied = (languageStudied.value)[findIndexDataById(languageStudied.value, props.formData.languageStudied)];
        } 
    });
}
const filteredLanguageStudied = ref();
const searchLanguageStudied = (event) => {
    // let _items = [...languageStudied.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredLanguageStudied.value = [...languageStudied.value];
        } else {
            filteredLanguageStudied.value = languageStudied.value.filter((item) => {
                return item.idioma.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const submitted = ref(false);
const emit = defineEmits([]);
const complete = (() => {
    if(!form.value.item){
        form.value.item = '';
    }
    console.log('send event')
    submitted.value = true;
    if (validateForm() ) {
        emit('complete', {formData: form.value});
    }    
});

const prevPage = () => {
    emit('prevPage', {pageIndex: 1});
}

const validateForm = (() => {
    // if (!form.value.education)
    //     validationErrors.value['education'] = true;
    // else
    //     delete validationErrors.value['education'];

    // if (!form.value.educationDescription)
    //     validationErrors.value['educationDescription'] = true;
    // else
    //     delete validationErrors.value['educationDescription'];
        
    if (!form.value.funding)
        validationErrors.value['funding'] = true;
    else
        delete validationErrors.value['funding'];
        
    if (!form.value.job)
        validationErrors.value['job'] = true;
    else
        delete validationErrors.value['job'];
    
    if (!form.value.specialty)
        validationErrors.value['specialty'] = true;
    else
        delete validationErrors.value['specialty'];
    
    if (!form.value.languageStudied)
        validationErrors.value['languageStudied'] = true;
    else
        delete validationErrors.value['languageStudied'];
    
    if (!form.value.normalist)
        validationErrors.value['normalist'] = true;
    else
        delete validationErrors.value['normalist'];
    
    if (!form.value.assignmentStartDate)
        validationErrors.value['assignmentStartDate'] = true;
    else
        delete validationErrors.value['assignmentStartDate'];
    
    // if (!form.value.assignmentEndDate)
    //     validationErrors.value['assignmentEndDate'] = true;
    // else
    //     delete validationErrors.value['assignmentEndDate'];

    return !Object.keys(validationErrors.value).length;
});

const formatFecha = (value, type) => {
    if(!value){
        return '';
    }
    let va = new Date(value);
    if(type === 1) {
        return va.toLocaleDateString('es-BO', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric' 
        });
    } else {
        var getYear = va.toLocaleString('es-BO', { year: "numeric" });
        var getMonth = va.toLocaleString('es-BO', { month: "2-digit" });
        var getDay = va.toLocaleString('es-BO', { day: "2-digit" });
        return getYear + "-" + getMonth + "-" + getDay;
    }
    
}


const findIndexDataById = (data, id) => {
    let index = -1;
    for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
            index = i;
            break;
        }
    }
    return index;
};

</script>