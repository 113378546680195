<template>
   
    <div class="flex justify-content-between  align-items-center pb-1">
        <span class="p-input-icon-left pr-2">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar" />
        </span>
        <Button type="button" size="small" label="Adicionar Cursos" icon="pi pi-plus" @click="createCareer()" v-if="role.rol !== 'DIRECTOR'" />
    </div>
    <DataTable :value="careers" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"  v-model:filters="filters">
        <Column> 
            <template #body="{data}">
                <div class="btn-group me-2" role="group" aria-label="First group"> 
                    <Button v-tooltip="'Ver Detalle'" icon="mdi mdi-eye" text raised rounded aria-label="Filter" @click="redirectCourseView(data.id)" />
                </div>
                <div class="btn-group me-2" role="group" aria-label="First group"  v-if="role.rol !== 'DIRECTOR'">
                    <Button v-tooltip="'Editar'" icon="mdi mdi-pencil" text raised rounded aria-label="Filter" @click="editCourse(data)" />
                </div>
                <div class="btn-group me-2" role="group" aria-label="First group" >
                    <!--<div class="btn-group me-2" role="group" aria-label="First group"  v-if="role.rol === 'ADMIN'">-->

                    <Button v-tooltip="'eliminar'" icon="pi pi-trash" severity="danger" text raised rounded aria-label="Filter" @click="deleteCourse(data.id)" />
                </div>
            </template>
        </Column>
        {{ data }}
        <Column field="nombreCurso" header="NOMBRE CURSO" sortable>
            <template #body="{data}"> {{ data.nombre}} </template>
        </Column>
        <Column field="area" header="ÁREA" sortable>
            <template #body="{data}"> {{ data.disciplinaTipo.area.area }} </template>
        </Column>
        <Column field="disciplina" header="DISCIPLINA" sortable>
            <template #body="{data}"> {{ data.disciplinaTipo.disciplina }} </template>           
        </Column>
        <Column field="tipoEducacion" header="TIPO INSTITUTO" sortable>
            <template #body="{data}"> {{ data.educacionTipo.educacion}} </template>
        </Column>
        <Column field="horas" header="HORAS REQUERIDAS" sortable>
        <template #body="{data}"> {{ data.horas_requeridas }} </template> 
        </Column>    
        <Column field="gestion" header="GESTIÓN" sortable>
        <template #body="{data}"> {{ data.gestiones.gestion }} </template>
        </Column>
        <Column field="totalClases" header="TOTAL CLASES" sortable>
        <template #body="{data}"> {{ data.total_clases }} </template>            
        </Column>
        <Column field="nivelAcademico" header="NIVEL DE FORMACIÓN" sortable>
        <template #body="{data}"> {{ data.nivelAcademico.nivelAcademico }} </template>
        </Column>          
        <Column field="cargaHoraria" header="CARGA HORARIA" sortable>
        <template #body="{data}"> {{ data.carga_horaria }} </template>            
        </Column>
        <Column field="tiempoEstudio" header="TIEMPO DE ESTUDIOS" sortable>
        <template #body="{data}"> {{ data.tiempo_estudio }} </template>            
        </Column>
      
        <template #paginatorstart>
                
        </template>
        <template #paginatorend>
               
            <strong> Total Registros {{ careers.length }}</strong>
     
        </template>
    </DataTable>

    <Dialog v-model:visible="dialog" modal :header="title" :style="{ width: '80vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-12">
                    <label>Tipo de Formación</label>
                   
                    <Dropdown v-model="educacion_tipo" :options="tipo_formacion"  filter optionLabel="educacion" optionValue="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tipo de Formación"
                    :class="{ 'p-invalid': !educacion_tipo && submitted }"
                    />
                    <small v-if="!educacion_tipo && submitted" class="p-error">Este campo es obligatorio.</small>

            </div>
            <div class="field col-12 md:col-12">
                    <label>Nombre del curso</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="course.nombre"  @input="course.nombre = course.nombre.toUpperCase()"
                    :class="{ 'p-invalid': !course.nombre && submitted }"
                    />
                    <small v-if="!course.nombre && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>
            <div class="field col-12 md:col-4">
                    <label>Área </label>
                    <Dropdown v-model="area_tipo"  filter :options="areas" optionLabel="area" optionValue="id" class=" p-inputtext-sm w-full " placeholder="Seleccionar Área"  @change="filterDisciplinas(area_tipo)"       
                    :class="{ 'p-invalid': !area_tipo && submitted }"
                    />
                    <small v-if="!area_tipo && submitted" class="p-error">Este campo es obligatorio.</small>

            </div>  
            <div class="field col-12 md:col-4">
                    <label>Disciplina</label>
                    <Dropdown v-model="disciplina_tipo" :options="disciplinas" optionValue="id" optionLabel="disciplina" class=" p-inputtext-sm w-full" placeholder="Seleccionar Disciplina" 
                    :class="{ 'p-invalid': !disciplina_tipo && submitted }"
                    />
                    <small v-if="!disciplina_tipo && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>
            <div class="field col-12 md:col-4">
                    <label>Nivel de Formación</label>
                    <Dropdown v-model="nivel_academico_tipo" :options="nivel" optionLabel="nivelAcademico" optionValue="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel" 
                    :class="{ 'p-invalid': !nivel_academico_tipo && submitted }"
                    />
                    <small v-if="!nivel_academico_tipo && submitted" class="p-error">Este campo es obligatorio.</small>

            </div>
            <div class="field col-12 md:col-4">
                    <label>Horas requeridas</label>
                    <InputNumber :integer="true" :useGrouping="false" class="p-inputtext-sm w-full" v-model="course.horas_requeridas"
                    :class="{ 'p-invalid': !course.horas_requeridas && submitted }"
                    />
                    <small v-if="!course.horas_requeridas && submitted" class="p-error">Este campo es obligatorio.</small>

            </div>
            <div class="field col-12 md:col-4">
                    <label>Gestión</label>
                    <Dropdown v-model="gestion" :options="gestions" optionLabel="gestion" optionValue="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Gestión" 
                    :class="{ 'p-invalid': !gestion && submitted }"
                    />
                    <small v-if="!gestion && submitted" class="p-error">Este campo es obligatorio.</small>

            </div>  
            <div class="field col-12 md:col-4">
                    <label>Total Clases</label>
                    <InputNumber :integer="true" :useGrouping="false" class="p-inputtext-sm w-full" v-model="course.total_clases"  
                    :class="{ 'p-invalid': !course.total_clases && submitted }"
                    />
                    <small v-if="!course.total_clases && submitted" class="p-error">Este campo es obligatorio.</small>

            </div> 
            <div class="field col-12 md:col-4">
                    <label>Carga Horaria</label>
                    <InputNumber :integer="true" :useGrouping="false" class="p-inputtext-sm w-full" v-model="course.carga_horaria"  
                    :class="{ 'p-invalid': !course.carga_horaria && submitted }"
                    />
                    <small v-if="!course.carga_horaria && submitted" class="p-error">Este campo es obligatorio.</small>

            </div>
            <div class="field col-12 md:col-4">
                    <label>Tiempo Estudio</label>
                    <InputNumber :integer="true" :useGrouping="false" class="p-inputtext-sm w-full" v-model="course.tiempo_estudio"   
                    :class="{ 'p-invalid': !course.tiempo_estudio && submitted }"
                    />
                    <small v-if="!course.tiempo_estudio && submitted" class="p-error">Este campo es obligatorio.</small>


            </div> 
            <div class="field col-12 md:col-4">
                    <label>Regimen de Estudios</label>
                    <Dropdown v-model="intervalo_gestion_tipo" :options="intervalos" optionLabel="intervaloGestion" optionValue="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Intervalo" 
                    :class="{ 'p-invalid': !intervalo_gestion_tipo && submitted }"
                    />
                    <small v-if="!intervalo_gestion_tipo && submitted" class="p-error">Este campo es obligatorio.</small>


            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="submitForm" autofocus/>
        </template>
    </Dialog> 
</template>
<script >
import InstitutionDataService from '@/services/InstitutionDataService'
import ArtisticaDataService from '@/services/ArtisticaDataService'
import CareerTypeDataService from '@/services/CareerTypeDataService'
import TrainingLevelDataService from '@/services/TrainingLevelDataService'
import StudyRegimenDataService from '@/services/StudyRegimenDataService'
import StudyTimeDataService from '@/services/StudyTimeDataService'
import ResolutionTypeDataService from '@/services/ResolutionTypeService'
import CareerDataService from '@/services/CareerDataServie'
import { FilterMatchMode } from 'primevue/api'
import ArtisticaAreasService from '@/services/ArtisticaAreasService'
import ArtisticaDisciplinasService from '@/services/ArtisticaDisciplinasService'
import ArtisticaNivelService from '@/services/ArtisticaNivelService'
import ArtisticaGestionService from '@/services/ArtisticaGestionService'
import FormacionSuperiorArtisticaService from '@/services/FormacionSuperiorArtisticaService'
import { resolveTransitionHooks } from 'vue'
import RegistrationStudentService from '@/services/RegistrationStudentService'
import GestionDataService from '@/services/GestionDataService'

export default{
    name: 'CareerInsitution',
    props: {
        iu_id: Number,
        //sucursal_id: Number,
    },
    data: ()=>({
        type :'',
        //educacion_tipo:{},
        course:{},
        educacion_tipo:null,
        area_tipo:null,
        disciplina_tipo:null,
        nivel_academico_tipo:null,
        gestion:null,
        intervalo_gestion_tipo:null,
        //registro: {
            //nombre: '',
            //educacion_tipo_id: null,
            /*area_tipo: null,
            disciplina_tipo: null,
            nivel_academico_tipo_id: null,
            intervalo_gestion_tipo_id:null,
            gestion_id: null,
            horas_requeridas: '',
            total_clases: '',
            carga_horaria: '',
            tiempo_estudio: '' ,
            area_tipo_id:null,
            disciplina_tipo_id:null*/
        //},
        tipo_formacion: [],
        areas: [],
        disciplinas: [],  
        nivel:[],
        gestions:[],
        intervalos:[],
        dialog: false,
        dialog_edit: false,
        careers:[],
        submitted: false,
        //instituto:[],
       

        //career:{},
        //form: {},
        //gestiones: [],
        //niveles: [],
        //area:{},
        //academic_levels:[],
        //academic_level:{},
        //study_regimens: [],
        //study_regimen:{},
        //study_times:[],
        //study_time: null,
        //resolution_types: [],
        //resolution_list: [],
        //resolution_type: {},
        //isEditMode: false,
        //institution:{},
      
        filters:{
                  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  nombreCurso: { value: null, matchMode: FilterMatchMode.CONTAINS },
                
        }
    }),
    
    mounted(){
        this.retrieveTipo();
        this.retrieveAreas();
        this.retrieveNivel();
        this.retrieveGestion();
        this.retrieveIntervalo();
        this.getCursosByInstitutoId();
        this.submitForm();
        this.getInstitution();
        //this.registro.institucion_educativa_id = this.$route.params.id;
        
    },
    computed: {
        institution_id()
        {
            return this.iu_id
        },
        title ()
        {
            return "Adicionar Curso"
        },
        role()
        {
            return this.$store.state.auth.rol
        },

        isFormValid() {
            
            return (
            this.course.nombre &&
            this.course.horas_requeridas &&
            this.course.carga_horaria &&
            this.course.tiempo_estudio &&
            this.course.total_clases &&
            this.educacion_tipo &&
            this.area_tipo &&
            this.intervalo_gestion_tipo
            );
            
        }
    },
    methods:{

        defaultRegistro() {
           this.registro = {};
        },

        /*format_date(value){
            if (value) {
            console.log('-->',value.substring(0, 10));
            return value.substring(0, 10)
            }
        },*/
        createCareer()
        {
            this.dialog = true
            this.course = {}
            this.type = 'nuevo';
            this.cleanForm();

        },
        async show(carrera_autorizada_id)
        {
            // console.log(carrera_autorizada_id)
           let response =  await CareerDataService.show(carrera_autorizada_id)
           this.form = response.data
           this.dialog_edit =  true
           
           this.form.resoluciones.forEach(element => {
            element.is_edited = false
            element.fechaResolucion = new Date(element.fechaResolucion)
            return element
           });
           console.log(this.form)
        },
        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                //console.log("esta es la institucion",this.institution)
            })
        },

        retrieveTipo(){
            ArtisticaDataService.getTrainingType()
            .then((response)=>{
                this.tipo_formacion = response.data.data
                //console.log("estos son los tipos", this.tipo_formacion)
            })
            .catch((e)=>{
                console.log(e)
            })
        },
        
        /*retrieveAreas(){
            ArtisticaAreasService.getAll()
                .then((response)=>{
                    this. areas = response.data.data
                    console.log('Lista de areas', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },*/
        retrieveAreas(){
            ArtisticaAreasService.getAll()
                .then((response)=>{
                    this. areas = response.data.data
                    console.log('Lista de areas', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveNivel(){
            ArtisticaNivelService.getAcademicLevel()
            .then((response)=>{
                    this.nivel = response.data
                    //console.log("nivel academico",this.nivel)
            })
        },
        /*retrieveGestion(){
            console.log('entra al servicio del front');
            ArtisticaGestionService.getAll()
                .then((response)=>{
                    //this. gestion = response.data.data
                    this.gestion = response.data.data.filter(option => option.id === 2024);  //quitar para que traiga toda la data 
                     console.log('jose', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },*/
        retrieveGestion(){
            GestionDataService.getAll()
                .then( (response) =>{
                    this.gestions = response.data;
                    //console.log("gestiones",this.gestions)
                })
        },

        retrieveIntervalo(){
            ArtisticaGestionService.getIntervalGestion()
            .then((response)=>{
                    this.intervalos = response.data
                   // console.log("intervalos",this.intervalos)
            })
        },
        /*retrieveCareerTypes()
        {
            CareerTypeDataService.getAllCareer()
                .then((response)=>{
                    this.career_types = response.data
                    // console.log('career types',response.data)
                })
        },*/
        
        /*retrieveTrainingLevel()
        {
            TrainingLevelDataService.getAll()
                    .then((response)=>{
                    this.academic_levels = response.data
                    // console.log(response.data)
                })
        },*/
        /*retrieveStudyRegimens()
        {
            StudyRegimenDataService.getAll()
                .then((response)=>{
                    this.study_regimens = response.data
                    // console.log(response.data)
                })
        },*/
        /*retrieveResolutionTypes()
        {
            ResolutionTypeDataService.getAll()
                .then((response)=>{
                    // this.study_regimens = response.data
                    this.resolution_types = response.data
                    console.log(response.data)
                })
            ResolutionTypeDataService.getList()
                .then((response) =>{
                    this.resolution_list = response.data
                } )
            
        },*/
        
        redirectCourseView(id)
        {   
            console.log('id ===>',id);
            this.$router.push(`/course/${id}`)
        },
        /*dateToDMY(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },*/

        /*formatFecha(value) {
            return va.toLocaleDateString('es-BO', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric' 
        });
        },*/

        saveCourse()
        {
            if(this.type === 'nuevo'){
                let new_course = {
                "educacion_tipo_id" :this.educacion_tipo,
                "disciplina_tipo_id":this.disciplina_tipo,
                "horas_requeridas":parseInt(this.course.horas_requeridas),
                "gestion_id":this.gestion,
                "total_clases":parseInt(this.course.total_clases),
                "carga_horaria":parseInt(this.course.carga_horaria),
                "tiempo_estudio":parseInt(this.course.tiempo_estudio),
                "nombre":this.course.nombre,
                "institucion_educativa_id":this.institution.ie_id,
                "intervalo_gestion_tipo_id":this.intervalo_gestion_tipo,
                "nivel_academico_tipo_id":this.nivel_academico_tipo,
                }
                FormacionSuperiorArtisticaService.create(new_course)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })

                            this.getCursosByInstitutoId();        
                            this.dialog = false;
                        }
                    })
            }
            if(this.type === 'editar'){
                let updated_course = {
                    "educacion_tipo_id": this.educacion_tipo,
                    "disciplina_tipo_id": this.disciplina_tipo,
                    "horas_requeridas": parseInt(this.course.horas_requeridas),
                    "gestion_id": this.gestion,
                    "total_clases": parseInt(this.course.total_clases),
                    "carga_horaria": parseInt(this.course.carga_horaria),
                    "tiempo_estudio": parseInt(this.course.tiempo_estudio),
                    "nombre": this.course.nombre,
                    "institucion_educativa_id": this.institution.ie_id,
                    "intervalo_gestion_tipo_id": this.intervalo_gestion_tipo,
                    "nivel_academico_tipo_id": this.nivel_academico_tipo,
                }
                // Editar curso
                FormacionSuperiorArtisticaService.editar(updated_course, this.course.id)

                    .then((response) => {
                        if (response.data.statusCode == 200) {
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Curso actualizado', life: 3000 });
                            this.getCursosByInstitutoId();        
                            this.dialog = false;
                        }
                    })
                    
            }            
        },
        editCourse(data) 
        {
            console.log("Editando curso...",data);            
            this.course.nombre = data.nombre;
            this.course.horas_requeridas = data.horas_requeridas;
            this.course.carga_horaria = data.carga_horaria;
            this.course.total_clases = data.total_clases;
            this.course.tiempo_estudio = data.tiempo_estudio;
            this.educacion_tipo = data.educacionTipo.id;
            this.nivel_academico_tipo = data.nivelAcademico.id;
            this.intervalo_gestion_tipo = data.intervaloGestion.id;
            this.gestion = data.gestiones.id;
            this.area_tipo = data.disciplinaTipo.area.id;
            this.disciplina_tipo =  data.disciplinaTipo.id
            this.course.id = data.id

            if (this.area_tipo) {
                this.filterDisciplinas(this.area_tipo);
            }
           
            this.updateCareer();
            //this.isEditMode = true;
        },  
        updateCareer()
        {
            this.type = 'editar';
            this.dialog = true;
           // this.saveCourse()
        },
        
        /*removeResolution(item,index){
            if(!item.id)
            {
                this.form.resoluciones.splice(index,1)
            }else
            {
                item.is_edited= false
            }

        },*/
        /*async editResolutionCareer(resolucion)
        {
            console.log('edit resolution',resolucion)
            let payload = {  
                numeroResolucion: resolucion.numeroResolucion+'',
                fechaResolucion: resolucion.fechaResolucion,
                resolucionTipoId: resolucion.resolucionTipo.id ,
                nivelAcademicoTipoId: resolucion.nivelAcademicoTipo.id,
                intervaloGestionTipoId: resolucion.intervaloGestionTipo.id,
                tiempoEstudio: parseInt(resolucion.tiempoEstudio) ,
                cargaHoraria: parseInt(resolucion.cargaHoraria),
                ultimo: resolucion.ultimo ?? false,
            }
            if(resolucion.id)
            {
                let response = await CareerDataService.editCareerResolution(resolucion.id, payload)
                console.log(response.data)
            }else{
                payload.carreraAutorizadaId = this.form.id
                let response = await CareerDataService.createCareerResolution(payload)
                console.log(response.data)
            }

           let response =  await CareerDataService.show(this.form.id)
           this.form = response.data
           this.dialog_edit =  false
           this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 }) 
            

            
        },*/
        /*async deleteResolutionCareer(resolucion)
        {
            this.$confirm.require({
                message: 'Esta seguro de eliminar la resolucion de la carrera?',
                header: 'Confirmacion',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'NO',
                acceptLabel: 'SI',
                accept: async () => {
                    let response = await CareerDataService.deleteCareerResolution(resolucion.id)
                    console.log(response.data)
                    this.dialog_edit =  false
                    this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },*/
        async deleteCourse(id)
        {   
            this.$confirm.require({
                message: 'Esta seguro de eliminar el curso?',
                header: 'Confirmacion',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'NO',
                acceptLabel: 'SI',
                accept: async () => {
                    //let response = await FormacionSuperiorArtisticaService.eliminar(id)
                    let response = await FormacionSuperiorArtisticaService.delete(id)
                    console.log(response.data);
                    this.getCursosByInstitutoId();
                    this.$toast.add({ severity: 'success', summary: 'Info', detail: 'Se elimino la carrera', life: 3000 });
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },

        getCursosByInstitutoId(){
            console.log('listar resultado');
            FormacionSuperiorArtisticaService.getCursosByInstitutoId(this.$route.params.id)
                .then((response)=>{
                    if(response.data.statusCode == 200)
                    {
                        //console.log('**********************');
                        //console.log(response.data.data);
                        this.careers = [];
                        this.careers = response.data.data;
                        //this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                    }
            })
        },

        filterDisciplinas(area_id){
            console.log("Esta es areaId", area_id)
            //console.log('area seleccionada => ', this.registro.area_tipo_id);
            //this.disciplinas = this.registro.area_tipo.disciplinas;
            //console.log("disciplinas", this.disciplinas)
            ArtisticaDataService.getDisciplinaByArea(area_id) 
            .then((response)=>{
                this.disciplinas = response.data.data
                   console.log("disciplinas",this.disciplinas)
                   if (!this.disciplinas.some(d => d.id === this.disciplina_tipo)) {
                        this.disciplina_tipo = null;
                    }
            })
         },
        
        submitForm() {
            this.submitted = true;
            //if (this.isFormValid) {
                // Guardar los datos del formulario
                //console.log("Formulario válido, guardando datos...", this.registro);
                this.saveCourse();
            //}
         },
        cleanForm(){
            this.course = {};
            this.educacion_tipo=null;
            this.area_tipo=null;
            this.disciplina_tipo=null;
            this.nivel_academico_tipo=null;
            this.gestion=null;
            this.intervalo_gestion_tipo=null;
        }
    }
}

</script>